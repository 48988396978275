@import "../../../layout/variables";
@import "../../../layout/sass/mixins";

.report-totalizadores-container {
  @include for(mobile-resolution) {
    flex-direction: column;
  }

  .report-totalizador {
    line-height: 25px;
    font-size: 12.5px;
    font-weight: 500;

    @include for(mobile-resolution) {
      text-align: left !important;
    }

    &-label {
      color: #3f434a;
      padding: 0.3rem;
      margin: -0.4em 1em -0.4em 0rem;
      min-width: 220%;
      font-weight: bold;
      line-height: 25px;
      font-size: 12.5px;
    }
  }
}

.report-title {
  @include for(mobile-resolution) {
    font-size: 16px !important;
  }
}

.legenda {
  width: 50%;

  @include for(mobile-resolution) {
    width: 100%;
  }
}

.legenda-title {
  @include for(mobile-resolution) {
    font-size: 13px !important;
  }
}

.legenda-row {
  @include for(mobile-resolution) {
    font-size: 12px !important;
  }
}