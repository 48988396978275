@import '../../../layout/sass/mixins';

.data-list-form {
	.p-datatable-thead {
		th {
			border-bottom: 1px solid black !important;
			padding-bottom: 5px !important;

			.p-column-title {
				color: black !important;
				font-size: 1rem !important;
			}
		}
	}
	.p-datatable-footer {
		padding-top: 30px !important;
		background-color: transparent !important;
		border-top: 1px solid black !important;
		border-bottom: none !important;
		
		@include for(mobile-resolution) {
      padding-top: 15px !important;
		}
	}
}
