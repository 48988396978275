@import "./_variables";
@import "./sass/mixins";

.p-disabled{
  cursor: text !important;
  pointer-events: auto !important;
}

.p-dialog-content{


  // .p-highlight{
  //   background-color:#304FFD !important;
  // }

  .field-radiobutton{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.select-buttons{
  .p-component, .p-button{
    border-color:transparent !important;
  }
}

}
.optionTable {
  display: flex;
  justify-Content: flex-end;
  margin: 10px 10px -10px 0px;
  align-Items: center;
  }

.SelectButtons{


  .p-highlight{
    background-color:#304FFD !important;
  }

  .field-radiobutton{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.select-buttons{
  .p-component, .p-button{
    border-color:transparent !important;
  }
}

}
.buttons-container {
  margin-bottom: 6px;
  margin-top: 6px;
  width: 100%;

  button {
    margin-right: 12px;
    float: left;
  }

  @media screen and (max-width: "640px") {
    display: block;

    button {
      margin: 0.5em 0;
      width: 100%;
      display: block;
    }
  }
}

.sombra-caixa{
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px; 
  padding: 20px;
  margin: 15px;
}

.sombra{
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px; 
}
.w-50{
width: 50%;
}

.login-smaller-link {
  color: #1A202C;
  font-size: 0.75rem;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}

.p-tabmenu {
  overflow: auto !important;
  background-color: white !important;
  border-radius: 25px 25px 0px 0px !important;

  .p-menuitem-link {
    border-color: transparent !important;
    padding: 20px !important;
    padding-bottom: 25px !important;
    font-weight: 500 !important;
    color: $bodyDark !important;
    box-shadow: none !important;
  }

  .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: $accent !important;
    border-bottom: 2px solid $accent !important;
  }
}

.p-inputgroup input {
  border-radius: 13px 0px 0px 13px !important;
}

.p-inputgroup button {
  border-radius: 0px 13px 13px 0px !important;
}

.p-inputgroup .p-inputgroup-buttons {
  border-radius: 0px 0px 0px 0px !important;
}

.container-button-Flex {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;

  button {
    margin-left: 0.5em;
  }
}

.container-title {
  margin-bottom: 10px;
}

.panel-container {
  margin-bottom: 15px;
}

.login-page {
  width: 100%;
  display: flex;
  flex-direction: column;
 

  .login-body {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100vh;
    @media (min-width: 1024px) {
      flex-direction: row;
    }

    .login-first-area {
      width: 50%;
      // height: 95vh;
      display: flex;
      flex-direction: column;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .login-background {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 1;

    }

    .login-background-conta {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      // height: 100%;
      width: 100%;
      
      @media (max-width: 1024px) {
        height: 100%;
        justify-content: flex-end;
        background-image: url('../assets/loginbar_bottom_mobile.svg');
        width: 100%;
        background-position: bottom;
        background-size: 130%;
        background-repeat: no-repeat;
        margin-bottom: -1px;
      }
      @media (max-height: 800px) {
        background-position: bottom;

      }

    }

    .login-fale-conosco{
      font-weight: bold;
      cursor: pointer
    }

    .grafico{
      @media (max-width: 1600px) {
        width: 70%;
    }

    }

    .login-bannerbottom {
      position: relative;
      z-index: -1;
      right: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;


      div{
        height: 30%;
    
      }
 
      @media (max-width: 1600px) {
          align-items: center;
          div{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 50%;
            
          }
        
      }

      @media (max-width: 1366px) {
        background-position: bottom top;
        background-size: 98%;
      
    }
      @media (max-width: 1024px) {
        width: 100%;
        height: 30%;
        // background-position: top;

        div{
          height: 100%;
        }
      }

      @media (max-width: 1024px) {
        background-image: none;
        height: auto;
        align-items: flex-end;
        margin: 0px 0px 8% 0px;

      
      }
    }

    .login-banner {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      background-image: url('../assets/loginbar_top.svg');
      width: 50%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;


      @media (max-width: 1024px) {
        display: none;
      }
    }


    .login-naotemconta {
      z-index: 1000;
      display: flex;
      font-size: 1rem;
      font-weight: 400;
      justify-content: center;
      align-items: flex-start;
      color: #1A202C;
      width: 100%;

      @media (min-width: 1024px) {
        bottom: 0;
        height: 40%;

      }
      @media (max-height: 768px) {
        bottom: 0;
        height: 100%;


      }

      @media (max-width: 1024px) {
        height: 70%;
        justify-content: center;
        align-items: center;

      }
      @media (min-height: 1300px) {
        height: auto;
        justify-content: center;
        align-items: center;

      }
      @media (max-height: 844px) {
        height: 50%;
        justify-content: center;
        align-items: center;



      }
     

      p{
        @media (max-width: 280px) {
          font-size: 1.6em;
          
        }
      }
    }

    .login-logo {
      z-index: 1000;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      @media (min-width: 1024px) {
        padding-top: 25px;
      }

      font-size: 16px;

    }

    .desktop {
      @media (min-width: 1024px) {
        display: none;
      }
      margin-top: 30px;
    }

    .login-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .login-form {
      width: 50%;
      // height: 95vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 10vh;
      background-image: url('../assets/loginbar_bottom.svg');
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: 95%;


      @media (max-width: 1024px) {
        width: 100%;
        height: 100vh;
        background-image: none;


      }
      @media (max-width: 1366px) {
        padding-top: 0;
      
    }
      // @media (max-height: 800px) {
      // }

    }

    .login-campos {
      width: 45%;
      height: 100%;

      .login-button{
        height: auto;
      }

      @media (max-width: 1600px) {
        width: 55%;

      }
      @media (max-width: 1024px) {
        width: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

.loading-container {
  margin: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.4);
}

.layout-logo {
  img {
    height: 40px;
  }
}

.layout-topbar {
  background: #ffffff !important;
}

.login-welcome {
  font-size: 24px;
  color: #1A202C;
  text-align: left;

  @media (max-width: 1024px) {}
}

.faca-login {
  font-size: 20px;
  color: #1A202C;
  text-align: left;

  @media (max-width: 1024px) {
    display: none;
  }
}

.list-selection {
  background-color: #fff;

  div {
    padding: 5px;
  }

  border-bottom: 1px solid $bodyLight;

  &.first {
    border-top: 1px solid $bodyLight;
  }

  &.last {
    border-bottom: none !important;
  }

  &:hover {
    background-color: #8080802a;
    cursor: pointer;
  }
}

label.observacao {
  display: block;
  color: grey;
  padding-left: 5px;
  font-size: 11px;
}

.label-over {
  position: absolute;
  color: gray;
  right: 15px;

  @media (min-width: 500px) {
    bottom: 8px;
  }
}

.label-over-cadastro {
  position: absolute !important;
  color: gray !important;
  right: 50px !important;
  bottom: -2px !important;
  font-size: 13px;

  @include for(medium-resolution) {
    font-size: 10px;
  }
}

.label-over-veiculo {
  position: absolute !important;
  color: gray !important;
  right: 50px !important;
  bottom: -2px !important;
  font-size: 13px;

  @include for(medium-resolution) {
    font-size: 10px;
  }
}

.label-over-vehicle {
  position: absolute;
  color: gray;
  left: 98px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 98px);

  @media (min-width: 500px) {
    bottom: 9px;
  }
}

.label-over-vehicle-cadastro {
  position: relative;
  color: gray;
  left: 98px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 98px);
  bottom: 35px;
}

$modal-sizes: (
  25: 25vw,
  30: 30vw,
  35: 35vw,
  40: 40vw,
  45: 45vw,
  50: 50vw,
  55: 55vw,
  60: 60vw,
  65: 65vw,
  70: 70vw,
  75: 75vw,
);

@each $size-width,
$width in $modal-sizes {
  .modal-vw-#{$size-width} {
    width: $width;

    @media (max-width: 1200px) {
      width: 68vw;
    }

    @media (max-width: 992px) {
      width: 84vw;
    }

    @include for(mobile-resolution) {
      width: 96vw;
    }
  }
}

.disabled-input {
  input:disabled {
    opacity: 0.81;
  }

  select:disabled {
    opacity: 0.81;
  }
}

.negociacao-buttons {
  @include for(mobile-resolution) {
    width: 100%;
    margin-top: 25px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    button {
      margin-bottom: 10px !important;
      width: 100% !important;
    }
  }
}

.p-dialog .p-dialog-content {
  padding-bottom: 0px !important;
}

.p-toast-message-content {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  // background: rgba(255, 231, 230, 0.496) !important;
} 

.p-toast .p-toast-message{
  backdrop-filter: blur(10px) !important;
  background-color: transparent !important;
  border-radius: 6px !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}


.p-toast .p-toast-message-error{
  background: rgba(255, 231, 230, 1) !important;
  border: solid #ff5757 !important;
  border-width: 0 0 0 6px !important;
  color: #bb3535 !important;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: rgba(255, 242, 226, 1) !important;
  border: solid #cc8925 !important;
  border-width: 0 0 0 6px !important;
  color: #cc8925 !important;
}

.p-toast .p-toast-message.p-toast-message-info {
  background: rgba(219, 234, 254, 1) !important;
  border: solid #3b82f6 !important;
  border-width: 0 0 0 6px !important;
  color: #2a5eb1 !important;
}


.p-toast .p-toast-message-success {
  background: rgba(228, 248, 240, 1) !important;
  border: solid #1ea97c !important;
  border-width: 0 0 0 6px !important;
  color: #1ea97c !important;
}

.p-toast-icon-close{
  height: 100% !important;
  margin-bottom: 20px !important;
  }

.neg-restante-row {
  padding: 0 0 0.4em 0;
  border-top: solid 1px #c8c8c8;

  &:first-child {
    border: 0px;
  }

  &:last-child {
    border-top: solid 1px #a9a9a9;
  }
}

.negociacao-info {
  font-size: 1.1em;
  margin-top: 3.5px;
  margin-bottom: 3.5px;
}

.rodape {
  background-color: #F2F7F2;
  color: #018085;
  display: flex;
  flex-direction: row;
  margin: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  justify-content: center;
  z-index: 1100;

  @media (max-width: 1024px) {
    display: none;
  }
}

.rodape-form {
  background-color: #F2F7F2;
  color: #018085;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 30%;
  border: 1px solid  #F2F7F2;
  @media (min-width: 1024px) {
    display: none;
  }
}

.link-integracao {
  padding-right: 500px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  bottom: 0;

  @media (max-width: 1024px) {
    display: none;
  }
}

.direitoReservado {
  position: relative;
  display: flex;
  font-size: 16px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 14px;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.fale-conosco {
  font-size: 16px;
  padding-left: 3px;
  font-weight: 700;
  color: #1A202C !important;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
}

.p-autocomplete-input {
  width: 100%;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 14px;

  @include for(medium-resolution) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.cadastrar-register-modal {
  text-decoration-line: underline;
  color: black;
}

.cadastrar-register-modal:hover {
  cursor: pointer;
}

.btn-option-container {
  background-color: $menuitemDarkActiveColor !important;
  border-radius: 5px !important;
  width: 270px !important;
  margin-bottom: 5px;
  margin-left: 20px;

  .btn-option {
    border-bottom: transparent !important;
    border-radius: 0px 5px 5px 0px !important;
    margin-left: 5px !important;
    background-color: $menuitemActiveRouteColor !important;
    width: 268px;
    border: none !important;

    &:hover {
      background-color: $menuitemDarkActiveColor !important;
    }
  }
}

.checkbox-documento-modal {
  margin-bottom: 14px !important;
  margin-top: 0px !important;

  label {
    margin-bottom: 14px !important;
  }
}

label {
  margin-bottom: 0px !important;
  color: black;
  font-style: normal;
}

.p-inputtext {
  color: #1f2124 !important;
}

.form-header {
  @include for(mobile-resolution) {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 12px;
      width: 100%;
    }
  }
}
.bordaEsqueda{
  background-color: red;
}
.drop-shadow{
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); 
}
.porcentagem-negativa{
  color: #F05C54;
}
.porcentagem-positiva{
  color: #20A144;
}

.p-sortable-column,
.p-selectable-row {
  font-size: 0.9em !important;

  @include for(medium-resolution) {
    font-size: 0.8em !important;
  }
}

// .frozen{
//   position: fixed;
// }

.p-column-title {
  @include for(medium-resolution) {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 446px) and (max-width: 576px) {

  .p-xsm-1,
  .p-xsm-2,
  .p-xsm-3,
  .p-xsm-4,
  .p-xsm-5,
  .p-xsm-6,
  .p-xsm-7,
  .p-xsm-8,
  .p-xsm-9,
  .p-xsm-10,
  .p-xsm-11,
  .p-xsm-12 {
    flex: 0 0 auto;
    padding: 0.5em;
  }

  .p-xsm-1 {
    width: 8.3333%;
  }

  .p-xsm-2 {
    width: 16.6667%;
  }

  .p-xsm-3 {
    width: 25%;
  }

  .p-xsm-4 {
    width: 33.3333%;
  }

  .p-xsm-5 {
    width: 41.6667%;
  }

  .p-xsm-6 {
    width: 50%;
  }

  .p-xsm-7 {
    width: 58.3333%;
  }

  .p-xsm-8 {
    width: 66.6667%;
  }

  .p-xsm-9 {
    width: 75%;
  }

  .p-xsm-10 {
    width: 83.3333%;
  }

  .p-xsm-11 {
    width: 91.6667%;
  }

  .p-xsm-12 {
    width: 100%;
  }
}

.required::after {
  content: "*";
  color: $darkRed;
}

.p-datatable-emptymessage td {
  padding: 10px !important;
}

.p-rowgroup-header-name {
  display: block;
  font-weight: bold;
  text-align: center;
}

.p-treetable td {
  padding: 5px !important;
}

.p-inputnumber-input {
  max-width: 100% !important;
}

.p-sortable-column .pi-sort-alt {
  display: none !important;
}

.active-route {

  span,
  i {
    // color: #212327;
    color: $heading !important;
  }

  background-color: $bg;
}

.separator {
  border-top: 1px solid #dadde0 !important;
  padding-top: 26px !important;
  margin-top: 23px !important;
}

.fileupload-veiculo {
  .p-fileupload-content {
    overflow: auto;

    .p-progressbar {
      visibility: hidden;
    }
  }
}

.imagem-container {
  position: relative;
}

.imagem {
  border: 1px solid rgb(214, 214, 214);
  margin: 3px;
  border-radius: 7px;
  display: block;
  width: 130px;
  height: 130px;
  transition: 0.33s ease;
  object-fit: cover;
}

.excluir-imagem {
  position: absolute !important;
  top: -0px !important;
  right: -0px !important;
  font-size: 0.8em !important;
  background-color: rgba(38, 38, 38, 0.96) !important;
  color: rgb(233, 233, 233) !important;
  font-weight: bold !important;
  padding: 4px !important;
  border-radius: 100% !important;
  border: none !important;
  cursor: pointer;
}

.p-fileupload-files {
  display: flex !important;
}

.p-message-error {
  display: none !important;
}

.p-message-enter-done {
  display: none !important;
}

.p-message-enter-done .p-message-wrapper {
  display: none !important;
}

.p-message-exit-active {
  display: none !important;
}

.underline {
  text-decoration: underline;
}

.width-40 {
  width: 40%
}

.width-35 {
  width: 35%
}

.HeaderDataList{
 span {
    display: inline !important;
  }
}

.wordWrapBreak{
  word-wrap: break-word;
}