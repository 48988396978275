@media print {
  @page {
    size: 100%;
    margin: 0.4cm 0.2cm;
  }

  .component-to-print-lucro-de-vendas-report {
    width: 100%;
    height: 100%;
    border: 2px solid white;
  }

  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
  }

  .report-title {
    font-size: 11.5px;
  }

  .p-accordion.report .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    padding: 4px;

    span {
      font-size: 10px;
    }
  }

  .p-accordion .p-accordion-content {
    border: 1.5px solid #e8e9eb;
  }

  .p-accordion-tab {
    page-break-after: auto !important;
    page-break-inside: avoid !important;
    page-break-before: auto !important;
  }

  .p-datatable.report-datatable {
    .p-datatable-thead > tr > th {
      padding: 0px !important;

      border-bottom: 1.5px solid #e8e9eb;

      .p-column-title {
        color: #3f4146 !important;
        font-weight: 600 !important;
        font-size: 8px !important;
      }

      .p-sortable-column-icon {
        font-size: 11px !important;
      }
    }

    .p-datatable-tbody > tr > td {
      padding: 2px !important;
      font-size: 9px;

      &:last-child {
        border-bottom: none !important;
      }
    }
  }

  h3 {
    font-size: 11px;
  }

  .legendas-container {
    display: flex !important;
    flex-direction: row !important;
  }

  .legenda {
    width: 50% !important;
  }

  .legenda-filtros {
    display: flex;
    justify-content: flex-end;
  }

  .legenda-title {
    font-size: 11px !important;
  }

  .legenda-row {
    font-size: 10px !important;
  }

  .report-totalizadores-container {
    flex-direction: row !important;

    .report-totalizador {
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 9px !important;
      width: 100%;
    }
  }

  .hidden {
    display: none !important;
    visibility: none !important;
  }

  .totalizadores-gerais-title {
    font-size: 11px !important;
  }

  .footer-totalizador {
    page-break-after: auto !important;
    page-break-inside: avoid !important;
    page-break-before: auto !important;

    .footer-totalizador-label {
      font-size: 9px !important;
    }

    div {
      font-size: 9px !important;
    }
  }
}
